<template>
  <div class="w-full h-full pt-5">
    <template>
      <div class="flex px-2">
        <h1 class="text-xl text-left font-extrabold mr-5">Compensation</h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>
    <template>
      <Card class="px-3 py-2 mx-2 my-5">
        <Card class="px-5 py-5 my-3 flex flex-col items-end">
          <CardFooter reloadcard class="-mt-5">
            <template v-slot:rightItems>
              <div class="rounded-md shadow p-3 ml-3 cursor-pointer">
                <Menu left @click.stop="">
                  <template v-slot:title>
                    <Icon icon-name="more_icon" size="xs" />
                  </template>
                  <!-- <template>
                    <div class="flex flex-col w-52 h-18 p-2 justify-start items-start">
                      <div class="hover:bg-ghostWhite w-full rounded-md">
                        <div class="flex justify-start items-center p-2 gap-2">
                          <Icon icon-name="icon-eye" class-name="text-carrotOrange" size="xs" />
                          <span class="text-darkPurple text-sm leading-5 font-normal">
                            View Pay Grade
                          </span>
                        </div>
                      </div>
                      <div class="hover:bg-ghostWhite w-full rounded-md">
                        <div class="flex justify-start items-center p-2 gap-2">
                          <Icon icon-name="icon-eye" class-name="text-darkPurple" size="xs" />
                          <span class="text-darkPurple text-sm leading-5 font-normal">
                            View Employees
                          </span>
                        </div>
                      </div>
                      <div class="hover:bg-ghostWhite w-full rounded-md">
                        <div class="flex justify-start items-center p-2 gap-2">
                          <Icon icon-name="edit" class-name="text-blueCrayola" size="xs" />
                          <span class="text-darkPurple text-sm leading-5 font-normal">
                            Adjust Pay Grade
                          </span>
                        </div>
                      </div>
                      <div class="hover:bg-ghostWhite w-full rounded-md">
                        <div class="flex justify-start items-center p-2 gap-2">
                          <Icon icon-name="icon-eye" class-name="text-mediumSeaGreen" size="xs" />
                          <span class="text-darkPurple text-sm leading-5 font-normal">
                            View Revision History
                          </span>
                        </div>
                      </div>
                    </div>
                  </template> -->
                </Menu>
              </div>
            </template>
          </CardFooter>
        </Card>
        <template v-if="isLoading">
          <div class="w-full my-32">
            <Loader size="xxs" :loader-image="false" />
          </div>
        </template>
        <template v-else>
          <template v-if="reviews.length">
            <Table
              :headers="headers"
              :items="reviews"
              :has-number="true"
              class="w-full"
              :loading="isLoading"
              :pagination-list="paginationList"
              page-sync
            >
              <template v-slot:item="{ item }">
                <div
                  v-if="item.revisionDate"
                  class="font-normal text-sm leading-5 tracking-widest"
                >
                  <span class="text-darkPurple" v-if="item.data.revisionDate">
                    {{
                      $DATEFORMAT(
                        new Date(item.data.revisionDate),
                        "MMMM dd,yyyy"
                      )
                    }}
                  </span>
                  <span v-else> - </span>
                </div>
                <div
                  v-if="item.financialYear"
                  class="font-normal text-sm leading-5 tracking-widest"
                >
                  <span class="text-darkPurple">{{
                    item.data.financialYear
                  }}</span>
                </div>
                <div
                  v-if="item.reviewCycle"
                  class="font-normal text-sm leading-5 tracking-widest"
                >
                  <span class="text-darkPurple">{{
                    item.data.reviewCycle
                  }}</span>
                </div>
                <div
                  v-if="item.revisionRange"
                  class="font-normal text-sm leading-5 tracking-widest"
                >
                  <span class="text-darkPurple">
                    {{ item.data.revisionRange.minRange }}% -
                    {{ item.data.revisionRange.maxRange }}%
                  </span>
                </div>
                <div
                  v-if="item.employeeCount"
                  class="font-normal text-sm leading-5 tracking-widest"
                >
                  <span class="text-darkPurple">{{
                    item.data.employeeCount
                  }}</span>
                </div>
                <div
                  v-if="item.timeline"
                  class="font-normal text-sm leading-5 tracking-widest"
                >
                  <span
                    class="text-darkPurple"
                    v-if="!isNaN(Date.parse(item.data.timeline.startDate))"
                  >
                    {{
                      $DATEFORMAT(
                        new Date(item.data.timeline.startDate),
                        "dd/MM/yyyy"
                      )
                    }}
                    -
                    {{
                      $DATEFORMAT(
                        new Date(item.data.timeline.endDate),
                        "dd/MM/yyyy"
                      )
                    }}
                  </span>
                  <span v-else> - </span>
                </div>
                <div
                  v-if="item.initiatedBy"
                  class="font-normal text-sm leading-5 tracking-widest"
                >
                  <span
                    class="text-darkPurple"
                    v-if="Object.keys(item.data.initiatedBy).length"
                  >
                    {{ item.data.initiatedBy.fname }}
                    {{ item.data.initiatedBy.lname }}
                  </span>
                </div>
                <div
                  v-if="item.approvalStatus"
                  class="font-normal text-sm leading-5 capitalize whitespace-nowrap"
                >
                  <span
                    class="px-2 py-1 rounded-md"
                    :class="
                      item.data.approvalStatus === 'in_progress'
                        ? 'bg-yellow-100 text-carrotOrange'
                        : item.data.approvalStatus === 'disapproved'
                        ? 'bg-red-100 text-desire'
                        : item.data.approvalStatus === 'approved'
                        ? 'bg-green-100 text-mediumSeaGreen'
                        : 'bg-gray-100 text-romanSilver'
                    "
                  >
                    {{ item.data.approvalStatus.split("_").join(" ") }}
                  </span>
                </div>
                <div v-if="item.id">
                  <Menu left @click.stop="">
                    <template v-slot:title>
                      <Icon icon-name="more_icon" size="xs" />
                    </template>
                    <template>
                      <div
                        class="flex flex-col w-44 h-18 p-2 justify-start items-start"
                      >
                        <div
                          class="hover:bg-ghostWhite w-full rounded-md cursor-pointer"
                        >
                          <div
                            @click="
                              $router.push({
                                name: 'EssCompensationReviewDetailedReport',
                                params: { id: item.data.id },
                              })
                            "
                          >
                            <div
                              class="flex justify-start items-center p-2 gap-2"
                            >
                              <Icon
                                icon-name="file_todo"
                                class-name="text-blueCrayola"
                                size="xs"
                              />
                              <span
                                class="text-darkPurple text-sm leading-5 font-normal"
                              >
                                Detailed Report
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="hover:bg-ghostWhite w-full rounded-md cursor-pointer"
                        >
                          <div @click="onSubmitDetailedReport(item.data.id)">
                            <div
                              class="flex justify-start items-center p-2 gap-2"
                            >
                              <Icon
                                icon-name="icon-upgrade"
                                class-name="text-carrotOrange"
                                size="xs"
                              />
                              <span
                                class="text-darkPurple text-sm leading-5 font-normal"
                              >
                                Submit
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="hover:bg-ghostWhite w-full rounded-md">
                          <div @click="onShowReviewTimelines(item.data)">
                            <div
                              class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                            >
                              <Icon
                                icon-name="icon-timer"
                                class-name="text-darkPurple -ml-1"
                                size="s"
                              />
                              <span
                                class="text-darkPurple text-sm leading-5 font-normal -ml-1"
                              >
                                Timelines
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="hover:bg-ghostWhite w-full rounded-md">
                          <div @click="toggleApprovalReviews(item.data.id)">
                            <div
                              class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                            >
                              <Icon
                                icon-name="icon-check-square"
                                class-name="text-mediumSeaGreen"
                                size="xs"
                              />
                              <span
                                class="text-darkPurple text-sm leading-5 font-normal"
                              >
                                Approvals Status
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </Menu>
                </div>
              </template>
            </Table>
          </template>
          <template v-else>
            <div
              class="flex flex-col justify-center items-center gap-5 px-10 pb-20 w-full"
            >
              <Icon
                size=""
                class-name="w-80 h-80"
                icon-name="comreview_illustration"
              />
              <p
                class="font-normal text-base leading-5 text-jet text-center w-3/6"
              >
                A list of compensation reviews for your organisaton will be
                shown here. Start by adding one using the button below.
              </p>
              <!-- <div class="flex justify-start items-center gap-2">
                <Icon size="xs" icon-name="icon-plus" />
                <p class="font-semibold text-base leading-5 text-flame">
                  Start New Review
                </p>
              </div> -->
            </div>
          </template>
        </template>
      </Card>
      <ReviewTimelines ref="ref_timelines" />
      <ApprovalStatus ref="approval_status" />
      <Modal v-if="deleteModal">
        <Card class="p-5 flex flex-col max-w-md">
          <div class>
            <p class="font-bold ml-4 my-5 text-center">
              Are you sure you want to delete this review?
            </p>
            <div class="flex justify-end items-center gap-2">
              <Button
                class="button-class-pimary btn-bg"
                label="Delete"
                @onClick="deleteReview(reviewId)"
              />
              <Button
                label="Cancel"
                class="button-class-secondary btn-border"
                @onClick="deleteModal = false"
              />
            </div>
          </div>
        </Card>
      </Modal>
    </template>
  </div>
</template>

<script>
import * as _ from "lodash";
import Loader from "@/components/Loader";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Menu from "@scelloo/cloudenly-ui/src/components/Menu";
import Button from "@scelloo/cloudenly-ui/src/components/button";
import CardFooter from "@/components/CardFooter";
import Modal from "@/components/Modal";
import Icon from "@/components/Icon";
import ReviewTimelines from "../Modals/ReviewTimelines";
import ApprovalStatus from "../Modals/ApprovalStatus";

export default {
  components: {
    Breadcrumb,
    Loader,
    Table,
    Card,
    Modal,
    Button,
    Menu,
    Icon,
    CardFooter,
    ReviewTimelines,
    ApprovalStatus,
  },
  data() {
    return {
      isLoading: true,
      deleteModal: false,
      reviews: [],
      reviewId: "",
      paginationList: {
        page: 1,
        lastPage: 1,
        total: 1,
        from: 1,
        to: 1,
      },
      breadcrumbs: [
        {
          disabled: false,
          text: "Compensation",
          href: "compensation",
          id: "Compensation",
        },
        {
          disabled: false,
          text: "Compensation Review",
          href: "CompensationReview",
          id: "CompensationReview",
        },
      ],
      headers: [
        { title: "revision date", value: "revisionDate" },
        { title: "financial year", value: "financialYear" },
        { title: "reviewCycle", value: "reviewCycle" },
        { title: "revision range", value: "revisionRange" },
        { title: "employee count", value: "employeeCount" },
        { title: "timeline", value: "timeline" },
        { title: "initiated by", value: "initiatedBy" },
        { title: "approval Status", value: "approvalStatus" },
        { title: "", value: "id", image: true },
      ],
    };
  },
  methods: {
    onShowReviewTimelines(payload) {
      this.$refs.ref_timelines.toggle(payload);
    },
    toggleApprovalReviews(payload) {
      this.$refs.approval_status.toggle(payload);
    },
    onSubmitDetailedReport(reviewId) {
      this.$_submitCompensationDetailedReport(reviewId)
        .then((response) => {
          if (response.data.success) {
            this.$toasted.success(response.data.message, {
              duration: 3000,
            });
          }
        })
        .catch((error) => {
          this.$toasted.error(error.message, {
            duration: 3000,
          });
        });
    },
    getOrgCompensationReview() {
      this.isLoading = true;
      const $fn = (fn) => fn || "-";
      this.$_getOrgCompensationReview()
        .then(({ data }) => {
          data.data?.forEach((review) => {
            const startDate = review.review.review_cycle?.startDate;
            const endDate = review.review.review_cycle?.endDate;
            const minRange = review.review.revisionRangeMinimum;
            const maxRange = review.review.revisionRangeMaximum;
            this.reviews.push({
              ...review,
              id: review.review.id,
              timelines: review.review.timelines,
              initiatedBy: review.initiatedBy || {},
              revisionDate: $fn(review.review.effectiveDate),
              reviewCycle: review.review.cycle.cycleName,
              financialYear: $fn(review.review.year),
              type: $fn(review.review.review_cycle?.reviewCycle),
              approvalStatus: $fn(review.review.status),
              employeeCount:
                _.size(review.review.eligible_employees?.employeeIds) || 0,
              timeline: { startDate: $fn(startDate), endDate: $fn(endDate) },
              revisionRange: {
                minRange: $fn(minRange),
                maxRange: $fn(maxRange),
              },
            });
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$toasted.error("Something went wrong", {
            duration: 3000,
          });
        });
    },
    handleDeleteReview(reviewId) {
      this.deleteModal = true;
      this.reviewId = reviewId;
    },
    deleteReview(id) {
      this.isLoading = true;
      this.deleteModal = false;
      this.$_deleteOrgCompensationReview(id)
        .then(() => {
          this.reviews = this.reviews.filter(
            (review) => !_.isEqual(review.id, id)
          );
          this.isLoading = false;
          this.$toasted.success("Review deleted successfully", {
            duration: 3000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.$toasted.error("Something went wrong.", {
            duration: 3000,
          });
        });
    },
  },
  created() {
    this.getOrgCompensationReview();
  },
};
</script>

<style>
.dropdownMenu {
  margin-right: 30px !important;
}
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 40px !important;
}
.button_class {
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  justify-self: end;
}
.button-class-pimary {
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}
.button-class-secondary {
  color: #321c3b !important;
  background: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
