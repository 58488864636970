<template>
  <RightSideBar
    v-if="isOpen"
    @close="isOpen=false"
    close-button
    :loading="isLoading"
    button-class="bg-dynamicBackBtn text-white"
    width="520px"
  >
    <template v-slot:title>
      <div class="flex justify-start items-center">
        <BackButton @onClick="isOpen=false" variant="secondary" />
        <p class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
          Approval Status
        </p>
      </div>
    </template>
    <template v-if="approvers.length" v-slot:subtitle>
      <template>
        <div class="flex flex-col justify-start gap-5">
          <div class="grid grid-cols-2 gap-2">
            <div v-for="(status, i) in statuses" :key="`${status}-${i}`">
              <div
                :class="approvalStatus === status ? 'r-active' : 'r-inactive'"
                class="rounded-md p-3 flex justify-start items-center gap-2 w-full"
              >
                <Icon
                  icon-name="icon-display-grid"
                  class-name="text-romainSilver"
                  size="xs"
                />
                <p class="text-sm leading-5 capitalize">
                  {{ status.split("_").join(" ") }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <p class="font-normal text-sm text-shinyBlack">
              Track the status of this approval here. See who has approved and
              who is yet to approve.
            </p>
          </div>
          <div class="mb-3">
            <p class="font-bold text-xs leading-5 text-darkPurple uppercase">
              {{ approvalStatus.split("_").join(" ") }}
            </p>
          </div>
        </div>
      </template>
    </template>
    <template v-if="approvers.length">
      <div class="flex flex-col gap-3">
        <div v-for="approver in approvers" :key="approver.userId">
          <Card class="2-full p-3 flex justify-between items-center">
            <div class="w-full"
              :class="{
                'border-desire border-l-4': approver.hasApproved === false,
                'border-mediumSeaGreen border-l-4': approver.hasApproved === true,
                'border-accentSquash border-l-4': approver.currentApprover === 'active',
                'border-romanSilver border-l-4': approver.currentApprover === 'inactive' || 'cancelled',
              }">
              <div class="w-full flex flex-col gap-2">
                <div class="w-full flex justify-between items-center">
                  <div class="flex justify-start items-start gap-2 ml-2">
                    <div class="rounded-md w-9 h-9" v-if="approver.photo">
                      <img
                        alt="photo"
                        :src="approver.photo"
                        class="rounded-md w-9 h-9"
                      />
                    </div>
                    <div v-else class="rounded-md w-9 h-9 border flex justify-center items-center">
                      <span class="text-center font-bold text-sm text-blueCrayola">
                        {{ $getInitials(`${approver.fname} ${approver.lname}`) }}
                      </span>
                    </div>
                    <div class="flex flex-col justify-start gap-2">
                      <p class="font-normal text-xs text-desire capitalize">
                        Approver
                      </p>
                      <div class="flex flex-col justify-start">
                        <p class="font-normal text-sm text-darkPurple capitalize">
                          {{ approver.fname }} {{ approver.lname }}
                        </p>
                        <p class="font-normal text-xs text-romanSilver capitalize">
                          {{ approver.designation }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col justify-start items-end">
                    <p
                      class="font-normal text-xs capitalize"
                      :class="{
                        c_approved: approver.hasApproved === true,
                        c_disapproved: approver.hasApproved === false,
                        c_pending: approver.currentApprover === 'active',
                        c_inactive: approver.currentApprover === 'inactive',
                        c_cancelled: approver.currentApprover === 'cancelled',
                      }"
                    >
                      <span v-if="approver.currentApprover === 'active'">Pending</span>
                      <span v-if="approver.currentApprover === 'cancelled'">Cancelled</span>
                      <span v-if="approver.currentApprover === 'inactive'">Not Started</span>
                      <template v-if="approver.currentApprover === 'hasApproved'">
                        <span v-if="approver.hasApproved">Approved</span>
                        <span v-else>Disapproved</span>
                      </template>
                    </p>
                    <template v-if="approver.currentApprover === 'hasApproved'">
                      <p class="font-semibold text-xs uppercase text-jet">
                        {{ $DATEFORMAT( new Date(approver.actionTakenAt), "MMMM dd, yyyy") }}
                      </p>
                    </template>
                  </div>
                </div>
                <div class="ml-12" v-if="approver.comment">
                  <div class="comment-box text-xs">
                    <p class="text-romanSilver text-xs">Comment</p>
                    <p class="text-darkPurple">{{ approver.comment }}</p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </template>
    <template v-if="!approvers.length">
      <div class="flex flex-col justify-center items-center gap-5 px-10 py-20">
        <Icon icon-name="paygrade_Illustration" size="" class-name="w-32 h-32" />
        <p class="font-normal text-base leading-5 text-jet text-center">
          Review is not yet submitted for approval. Please kindly submit for approval to commence
        </p>
      </div>
    </template>
  </RightSideBar>
</template>

<script>
import * as _ from "lodash"
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import RightSideBar from "@/components/RightSideBar";

export default {
  components: {
    Card,
    BackButton,
    RightSideBar
  },
  data() {
    return {
      isLoading: true,
      isOpen: false,
      approvers: [],
      approvalStatus: '',
      statuses: [
        "not_started",
        "in_progress",
        "approved",
        "disapproved"
      ]
    }
  },
  methods: {
    toggle(requestId){
      this.isOpen = true
      this.getApprovalRequestProgress(requestId)
    },
    getApprovalRequestProgress(requestId){
      this.isLoading = true
      this.$_getApprovalProgress(requestId).then(({ data }) => {
        if(_.every(data.data, ['hasApproved', Boolean])){
          if(_.every(data.data, ['hasApproved', true])){
            this.approvalStatus = 'approved'
          } else this.approvalStatus = 'disapproved'
        } else if(_.some(data.data, ['currentApprover', 'active'])){
          this.approvalStatus = 'in_progress'
        } else if(_.every(data.data, ['currentApprover', 'inactive'])){
          this.approvalStatus = 'not_started'
        }
        this.approvers = data.data?.map((approver) => ({
          ...approver, designation: approver.userDesignation.name
        }))
        this.isLoading = false
      })
    }
  }
};
</script>

<style>
.rightSidebar {
  width: 520px !important;
}
.text-yonda {
  color: #667499;
}
.r-active {
  background: #fe4d3c;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.5px;
}
.r-inactive {
  background: #f6f8f9;
  border-radius: 5px;
  color: #667499;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.5px;
}
.comment-box {
  padding: 8px;
  background: rgba(247, 181, 56, 0.12);
  border: 0.6px solid #f7b538;
  border-radius: 8px;
}
.c_approved {
  color: #13b56a;
}
.b_approved {
  border: 2px solid #13b56a;
}
.c_pending {
  color: #f7b538;
}
.b_pending {
  border: 2px solid #f7b538;
}
.c_disapproved {
  color: #fe4d3c;
}
.b_disapproved {
  border: 2px solid #fe4d3c;
}
.c_cancelled {
  color: #9ba0a7;
}
.c_inactive {
  color: #9ba0a7;
}
.b_cancelled {
  border: 2px solid #9ba0a7;
}
.b_inactive {
  border: 2px solid #9ba0a7;
}
</style>
